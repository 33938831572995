import axios from 'axios'

export function login({
  student_pwd,
  student_email
}) {
  return axios.post('./index.php', {
      function: 'loginByStudent',
      student_pwd,
      student_email,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function loginByContact({
  contact_email,
  contact_pwd
}) {
  return axios.post('./index.php', {
    function: 'loginByContact',
    contact_pwd,
    contact_email,
  })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getStudentClasses({
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getStudentClasses',
      student_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}


export function getSessions(ps) {
  return axios.post('./index.php', {
    function: 'getSessions',
    ...ps
  })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getContents({
  session_id,
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getContents',
      session_id,
      student_id,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getContent({
  content_id,
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getContent',
      content_id,
      student_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}
export function uploadQuiz({
  quiz_id,
  student_id,
  quiz_answer,
}) {
  return axios.post('./index.php', {
      function: 'uploadQuiz',
      quiz_id,
      student_id,
      quiz_answer,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function netService({
  functionName,
  ...p
}) {
  return axios.post('./index.php', {
    function: functionName,
    ...p
  })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getAdminClasses({
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getAdminClasses',
      student_id,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getStudentStatsByClass(
  class_id
) {
  return axios.post('./index.php', {
      function: 'getStudentStatsByClass',
      class_id,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}


export function resetQuiz(
  user_id,
  class_id,
  session_id
) {
  return axios.post('./index.php', {
      function: 'resetQuiz',
      user_id,
      class_id,
      session_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}