<template>
  <div>
    <div v-for="quiz in quizs" :key="quiz._id.$id">
      <div style="text-align: left; font-size: 16px" class="quiz"  v-if="quizs.length && !isDone" >
        <span>{{quiz.question_title}}</span>
        <div>
          <el-radio-group v-model="quiz.question_answer"  :disabled="isOneDone">
              <el-radio  v-for="(v, index) in quiz.question_options" :key="v" class="choose" :label="index+1" >{{chooseKey[index]}}. {{v}}{{quiz.question_answer == index+1 ? '√':''}}</el-radio>
          </el-radio-group>
        </div>
        <span class="answer" >此题答案: {{chooseKey[+quiz.question_answer-1]}}</span>

        
      </div>
    </div>
  </div>

</template>

<script>
import { uploadQuiz } from '../service.js'

export default {
  name: 'QuizStatic',
  props: {
    quizs: Array,
  },
  components: {
    
  },
  mounted(){
    console.log(this.isDone,this.quizs)
  },
  watch: {
   
  },
  data() {
    return {
      chooseKey: ['A', 'B', 'C', 'D', 'E', 'F'],
      isDone: false,
      isOneDone: false,
      answer: null,
      question: {
        now_index: 1,
        question_title: 'ceshi',
        question_options: ['1','2','3','4'],
        question_answer: '1',
        _id: {
          $id: '5b08289209f7789c538b45d4'
        }
      }
    }
  },
  methods: {
    goNext() {
      const old_index = this.question.now_index
      this.question = {
        ...this.quizs[old_index],
        now_index: old_index + 1,
      }
      this.isOneDone = this.quizs[old_index].quiz_answer!==undefined,
      this.answer = this.quizs[old_index].quiz_answer 
      // this.$forceUpdate()
    },
    done() {
      this.isDone = true
      this.onDone()
      this.isDone = false
      this.isOneDone = false,
      this.answer = null
    },
    clickC(now_index) {
      now_index === this.quizs.length ? this.done() : this.goNext()
    },
    oneDone() {
      this.isOneDone = true
      console.log(this.quizs)
      console.log(this.question.now_index)
      this.quizs[this.question.now_index-1].quiz_answer=this.answer
      uploadQuiz({
        quiz_id: this.question._id.$id,
        student_id: this.studentId,
        quiz_answer: this.answer,
        class_id: this.quizs[this.question.now_index-1].class_id
      })
    },
    onChangeIndex(index){
      //  const old_index = this.question.now_index
      this.onCI(index)
      this.question = {
        ...this.quizs[index],
        now_index: index+1,
      }
      this.isOneDone = this.quizs[index].quiz_answer!==undefined,

      // this.isOneDone = !!this.quizs[index].quiz_answer,
      console.log('your ansert:',this.quizs[index].quiz_answer )
      this.answer = this.quizs[index].quiz_answer 
    }
    

  },
  computed:{
    name(){
      return this.$store.state.user_info.student_lastName + this.$store.state.user_info.student_givenName
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quiz{
  padding: 20px;
}
.choose{
  margin-top:30px;
  display: block;
}
.answer{
  margin-top: 30px;
  margin-right: 20px;
  display: inline-block;
}
.question-button{
  right: 20px;
  /* position: absolute; */
  margin-top: 20px;
}
.el-radio+.el-radio {
    margin-left: 0;
}
</style>
