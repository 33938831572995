<template>
  <div class="Home">
    <Head />
    <el-container style="width: 80%; margin: 0 auto">
      <el-table
      :data="tableData2"
      style="width: 80%"
      >
      <el-table-column
        prop="name"
        label="姓名"
        >
      </el-table-column>
      <el-table-column
        prop="account_name"
        label="学校名"
        >
      </el-table-column>
      <el-table-column label="正确率">
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.total_correct*100 / scope.row.total_quiz" :show-text="false"></el-progress>
          {{scope.row.total_correct}}/{{scope.row.total_quiz}}
        </template>
      </el-table-column>
      <el-table-column label="进度">
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.total_quiz*100 / count" :show-text="false"></el-progress>
          {{scope.row.total_quiz}}/{{count}}
        </template>
      </el-table-column>
      
    </el-table>
    </el-container>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getStudentStatsByClass } from '../service.js'
import Head from './Head'

export default {
  name: 'StudyProcess',
  props: {
    
  },
  mounted(){
    this.class_id = this.$router.currentRoute.query.class_id
    this.count = this.$router.currentRoute.query.count
    this.getStatus(this.class_id)
  },
  components: {
    Head
  },
  methods: {
    getStatus(class_id){
      getStudentStatsByClass(class_id).then(res => {
        console.log(res)
        this.tableData2 = res
      })
    },
    format(cur, tot) {
        return `${cur}/${tot}`;
      
    }
  },  
  data(){
    return {
      contents:[],
      tableData2: [],
      count: 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pdf{
    position: fixed;
    top: 3%;

    right: 1%;
    background-color:#1863bb;
    color:white;


  }
  .Home {
    width: 100%;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
