<template>
  <div class="messageList">
      <Head />
      <div class="comments">
              <!-- <div style="margin-bottom:20px">我的内容：{{my_block}}</div> -->
               Q&A：{{comments.length === 0 ? '无': ''}}
              <div v-for="item in comments" :key="item._id.$id" style="margin-top: 10px;border-bottom: 1px solid #ccc;">
                {{item.message_content}}
                <span style="color:#ccc;font-size:12px;padding:5px">{{new Date(item.create_time * 1000).toLocaleDateString()}}</span>
                <div style="font-size:12px;padding:16px;color:#999">{{item.reply_text}}</div>
              </div>
        </div>
  </div>
</template>

<script>
import { netService } from '../service.js'
import Cookie from 'js-cookie'
import Head from './Head'

export default {
  name: 'Message',
  mounted() {
    const studentid = Cookie.get('studentid')
    netService({
      functionName: 'findMyMessages',
      student_id: studentid
    }).then((data) => {
      
      this.comments = JSON.parse(data)

    })
  },
  components: {
    Head,
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        console.log(row)
        this.$router.push({ path: 'sessionList', query: { class_id: row.class_id }})
      }
    },
    data() {
      return {
        comments: []
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
  .avatar_url{
    width: 50px;
    height: 50px;
    transform: translate(0, 10%);
    /* border-radius: 50%;  */
  }
  .messageList{
    width:100%
  }
</style>
<style>
.el-table__row{
      cursor: pointer;
}
</style>