<template>
  <div  class="Home">
    <Head />
    <el-table
      :data="tableData2"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      >
     
      <el-table-column
        prop="project_name"
        label="项目名"
        width="280"
        >
      </el-table-column>
      <el-table-column
        prop="project_type"
        
        label="项目类型">
      </el-table-column>
      <el-table-column
        prop="project_price"
        label="项目价格 ">
      </el-table-column>
      
      <el-table-column
        prop="project_year"
        label="项目时间">
      </el-table-column>

      <el-table-column
        
        >
        <template slot-scope="scope">
          <div style="text-align: center" >
             <el-button type="primary" @click="sign(scope.row)" :disabled="scope.row.app_status !== 'no'" :loading="signLoading">{{ scope.row.app_status == 'no'?'报名':'已报名'}}</el-button>
          </div>
          
        </template>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import Head from './Head'
import { netService } from '../service.js'

export default {
  name: 'SignList',
  props: {
    
  },
  components: {
    Head,
  },
  mounted() {
    const studentid = Cookie.get('studentid')
    this.studentid = studentid
    console.log(studentid)
    netService({
      functionName: 'getProjects',
      user_id: studentid,
      
    }).then((data => {
      console.log(data)
      this.tableData2 = data
    }))
  },
  computed:{
    name(){
      return Cookie.get('user_name')
    }
  },
  methods: {
   tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        console.log(row)
        this.$router.push({ path: 'sessionList', query: { class_id: row.class_id }})
      },
      sign(row){
        console.log(row)
        this.signLoading = true
        netService({
          functionName: 'addApplication',
          user_id: this.studentid,
          project_id: row._id.$id

          
        }).then((data => {
          console.log(data)
          this.signLoading = false

          row.app_status = 'yes'
        }))
      }

  },
  data(){
    return {
      tableData2: [],
      signLoading: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .Home {
    width: 100%;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
  .avatar_url{
    width: 50px;
    height: 50px;
    transform: translate(0, 10%);
    /* border-radius: 50%;  */
  }
</style>

<style>
.el-table__row{
      cursor: pointer;
}
</style>
